@charset 'utf-8';

@import "fonts";//フォントの指定
@import "glov";//その他の指定

header{
	width: 100%;
}

.header_wrap{
	flex-direction: column;
	padding: 10px 0;
}

.head_m_wrap{
	width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
}

nav{
	width: 100%;
	justify-content: center;
	ul{
	align-items: center;
		li{
			padding: 4px 10px;
			transition: 0.2s;
			&:hover{
			opacity: 0.7;
			}
		}
		li+ li {
		border-left: 3px solid $main-font-color;
		}
	}
}



.logo_wrap{
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	justify-content: space-between;
	.logo_l{
		align-items: center;
		p{
			margin-left: 35px;
		}
	}
	.logo_r{
		align-items: center;
	}
}


.ot_wrap {
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}

.ot_wrap a {
  height: 100%;
  background-color: $base-color;
  padding: 15px 60px;
  position: relative;
  transition: 0.2s;
	  &:before {
		content: '';
	    width: calc(100% + -3px);
	    height: calc(100% + 1px);
	    border: 2px solid #37b2b2;
	    position: absolute;
	    top: 0px;
	    left: 0px;
		}
		&:hover{
			opacity: 0.7;
		}
}

.tel_wrap{
	text-align: center;
	p{
		margin-bottom: 6px;
	}
}

.groval_wrap{
	width: 100%;
}

.mv_wrap{
	width: 100%;
	position: relative;
	.mvin_wrap{
		width: 100%;
		img{
			width: 100%;
		}
	}
}

.mv_text{
	position: absolute;
	top: 10%;
	left: 15%;
	h2{
		margin-top: 20px;
		line-height: 1.1;
	}
	.flex_area{
		margin-top: 20px;
		align-items: center;
	}
	.blue_b{
		text-align: center;
		width: 17rem;
		padding: 10px 0;
		background-color: $base-color;
		color: $font-color-white;
		margin-right: 5px;
	}
	.text_u{
		margin-top: 15px;
	}
}

.section1_wrap{
	width: 100%;
	margin-top: 30px;
	background:linear-gradient(102deg,$base-color 0%,$base-color 65%,white 50%,white 100%);
	.se1_in{
		padding: 40px 0;
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		justify-content: space-around;
		.se1_title{
			line-height: 1.7;
		}
		.se1_utext{
			word-wrap: break-word;
			margin-top: 30px;
			line-height: 1.7;
		}
		.se1_img{
			margin-top: 40px;
			position: relative;
		}
		.s_box{
			display: block;
			position: absolute;
			content: '';
		    width: calc(100% + 20px);
		    height: calc(100% + -13px);
		    border: 2px solid #37b2b2;
		    top: 20px;
		    left: 0px;
		    z-index: 1;
		}
		img{
			position: relative;
			z-index: 2;
		}
	}
}

.section2{
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	margin-top: 90px;
	h2{
		width: 100%;
		text-align: center;
	}
	.se2_in{
		margin-top: 90px;
		width: 100%;
		padding: 0 60px;
	}

}

.se2_in{
	justify-content: space-between;
	.se2_l{
		width: 45%;
		img{
			width: 100%;
		}
		a{
			width: 60%;
			display: block;
			margin: 0 auto;
			margin-top: 22px;
			padding: 10px 65px;
			background-color: $base-color;
			border-radius: 45px;
			text-align: center;
			transition: 0.2s;
			&:hover{
				opacity: 0.7;
			}
		}
		p.fs_16{
			line-height: 1.7;
			margin-top: 20px;
		}
		p.fs_20{
			margin-top: 40px;
			text-align: center;
		}
		p.lit_tx{
			margin-top: 30px;
			line-height: 1.7;
		}
	}
	.se2_r{
		width: 45%;
		img{
			width: 100%;
		}
		a{
			width: 60%;
			display: block;
			margin: 0 auto;
			margin-top: 22px;
			padding: 10px 65px;
			background-color: $base-color;
			border-radius: 45px;
			text-align: center;
			transition: 0.2s;
			&:hover{
				opacity: 0.7;
			}
		}
		p.fs_16{
			line-height: 1.7;
			margin-top: 20px;
		}
	}
}

.section3{
	width: 100%;
	background-color: #e8e8e8;
	margin-top: 110px;
	.se3_in_wrap{
		max-width: 1200px;
		margin: 0 auto;
		background-image: url("../img/b1.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.se3_in{
		width: 100%;
		padding: 70px 110px;
		h2{
			text-align: center;
		}
		p.fs_18{
			margin-top: 30px;
			line-height: 1.7;
		}
	}
}

.section4{
	margin-top: 90px;
	width: 100%;
	.se4_in_wrap{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		h2{
			width: 100%;
			text-align: center;
		}
	}
}

.se4_in{
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 40px;
	.colum_wrap{
		margin-top: 40px;
		width: 30%;
		line-height: 0;
		img{
			width: 100%;
			transition: 0.2s;
			&:hover{
				opacity: 0.7;
			}
		}
		h3{
			width: 100%;
			text-align: center;
			margin-top: 45px;
		}
		hr{
			margin-top: 30px;
			border: solid 1px $base-color;
		}
		.se4_text{
			margin-top: 15px;
			p{
				line-height: 1.5;
			}
		}
	}
}

.section5{
	width: 100%;
	background-image: url("../img/b2.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 90px;
	.se5_in_wrap{
		width: 100%;
		max-width: 1200px;
		padding: 90px 0;
		margin: 0 auto;
		h2{
			width: 100%;
			text-align: center;
		}
	}
}

.se5_in{
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 40px;
	.colum_wrap{
		margin-top: 40px;
		width: 30%;
		line-height: 0;
		img{
			width: 100%;
			transition: 0.2s;
			&:hover{
				opacity: 0.7;
			}
		}
		h3{
			width: 100%;
			text-align: center;
			margin-top: 30px;
			padding: 24px 0;
			background-color: $base-color;
		}
		.se5_text{
			margin-top: 15px;
			p{
				line-height: 1.5;
			}
		}
	}
}

.foot_wrap{
	width: 100%;
	background-color: $base-color;
	padding-top: 20px;
	.foot_u{
		width: 100%;
		background-color: #464646;
		.foot_u_in{
			padding: 30px 0;
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			p{
				margin-top: 15px;
				text-align: center;
			}
			ul{
				align-items: center;
				justify-content: center;
				li{
					padding: 4px 10px;
					transition: 0.2s;
					&:hover{
						opacity: 0.7;
					}
					a{
						color: #fff;
					}
				}
				li+ li {
					border-left: 3px solid #fff;
				}
			}
		}
	}
}
