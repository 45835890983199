@charset 'utf-8';


$font-mei:'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
$font-yugo: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$font-kaku:'Hiragino Kaku Gothic Pro','ヒラギノ角ゴ Pro W3','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;


//フォント関係
$main-f-style: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
$main-font-color: #111;
$font-color-white: #fff;
$font-color-blue: #37b2b2;
$font-color-red: #f71516;
$font-color-green: #009520;
$font-color-blue2: #0e2585;
$font-color_orenge: #f39700;


//カラー関係
$base-color: #37b2b2;
$sub-color: #fff43d;
$line-color-yellow: #fff43d;
$button1-base-color: #f18909;
$button1-shadow: #d17607;
$button2-base-color: #eb6100;
$border-color-g: #888;
$rank-bar-color: #58abfb;

//装飾用
p{
	font-family: $main-f-style;
	color: $main-font-color;
}

h2{
	font-family: $main-f-style;
	font-size: 4rem;
	font-weight: bold;
	color: $base-color;
}

h3{
	font-family: $main-f-style;
	font-size: 3rem;
	font-weight: bold;
	color: $main-font-color;
}

a {
	color: $main-font-color;
	font-family: $font-yugo;
	text-decoration: none;
	cursor: pointer;
}

.fs_12{
	font-family: $main-f-style;
	font-size: 1.2rem;
}

.fs_14{
	font-family: $main-f-style;
	font-size: 1.4rem;
}

.fs_16{
	font-family: $main-f-style;
	font-size: 1.6rem;
	color: $main-font-color;
}

.fs_18{
	font-family: $main-f-style;
	font-size: 1.8rem;
	color: $main-font-color;
}

.fs_20{
	font-family: $main-f-style;
	font-size: 2rem;
	color: $main-font-color;
}

.fs_24{
	font-family: $main-f-style;
	font-size: 2.4rem;
	color: $main-font-color;
}

.fs_30{
	font-family: $main-f-style;
	font-size: 3rem;
	color: $main-font-color;
}

.fs_32{
	font-family: $main-f-style;
	font-size: 3.2rem;
	color: $font-color-white;
	font-weight: bold;
}

.fs_36{
	font-family: $main-f-style;
	font-size: 3.6rem;
	color: $font-color-white;
	font-weight: bold;
}

.fs_80{
	font-family: $main-f-style;
	font-size: 8rem;
	color: $main-font-color;
}

.fs_b{
	font-weight: bold;
}

.fc_r{
	color: $font-color-red;
}

.fc_b{
	color: $font-color-blue;
}

.fc_w{
	color: $font-color-white;
}

.fc_g{
	color: $font-color-green;
}

.fc_b2{
	color: $font-color-blue2;
}

.fc_m{
	color: $main-font-color;
}

.m_t10{
	margin-top: 1rem;
}

.m_t30{
	margin-top: 3rem;
}

html {
    /* ルートのフォントサイズ10px */
    font-size: 62.5%;
}

body {
	font-size: 1.6em;
}

body *{
	box-sizing: border-box;
	margin: 0;
}

.flex_area{
	display: flex;
}

