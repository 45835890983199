@charset "UTF-8";
p {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  color: #111;
}

h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 4rem;
  font-weight: bold;
  color: #37b2b2;
}

h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: #111;
}

a {
  color: #111;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  text-decoration: none;
  cursor: pointer;
}

.fs_12 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 1.2rem;
}

.fs_14 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 1.4rem;
}

.fs_16 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 1.6rem;
  color: #111;
}

.fs_18 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 1.8rem;
  color: #111;
}

.fs_20 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 2rem;
  color: #111;
}

.fs_24 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 2.4rem;
  color: #111;
}

.fs_30 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 3rem;
  color: #111;
}

.fs_32 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 3.2rem;
  color: #fff;
  font-weight: bold;
}

.fs_36 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 3.6rem;
  color: #fff;
  font-weight: bold;
}

.fs_80 {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-size: 8rem;
  color: #111;
}

.fs_b {
  font-weight: bold;
}

.fc_r {
  color: #f71516;
}

.fc_b {
  color: #37b2b2;
}

.fc_w {
  color: #fff;
}

.fc_g {
  color: #009520;
}

.fc_b2 {
  color: #0e2585;
}

.fc_m {
  color: #111;
}

.m_t10 {
  margin-top: 1rem;
}

.m_t30 {
  margin-top: 3rem;
}

html {
  /* ルートのフォントサイズ10px */
  font-size: 62.5%;
}

body {
  font-size: 1.6em;
}

body * {
  box-sizing: border-box;
  margin: 0;
}

.flex_area {
  display: flex;
}

header {
  width: 100%;
}

.header_wrap {
  flex-direction: column;
  padding: 10px 0;
}

.head_m_wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
}

nav {
  width: 100%;
  justify-content: center;
}

nav ul {
  align-items: center;
}

nav ul li {
  padding: 4px 10px;
  transition: 0.2s;
}

nav ul li:hover {
  opacity: 0.7;
}

nav ul li + li {
  border-left: 3px solid #111;
}

.logo_wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

.logo_wrap .logo_l {
  align-items: center;
}

.logo_wrap .logo_l p {
  margin-left: 35px;
}

.logo_wrap .logo_r {
  align-items: center;
}

.ot_wrap {
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}

.ot_wrap a {
  height: 100%;
  background-color: #37b2b2;
  padding: 15px 60px;
  position: relative;
  transition: 0.2s;
}

.ot_wrap a:before {
  content: '';
  width: calc(100% + -3px);
  height: calc(100% + 1px);
  border: 2px solid #37b2b2;
  position: absolute;
  top: 0px;
  left: 0px;
}

.ot_wrap a:hover {
  opacity: 0.7;
}

.tel_wrap {
  text-align: center;
}

.tel_wrap p {
  margin-bottom: 6px;
}

.groval_wrap {
  width: 100%;
}

.mv_wrap {
  width: 100%;
  position: relative;
}

.mv_wrap .mvin_wrap {
  width: 100%;
}

.mv_wrap .mvin_wrap img {
  width: 100%;
}

.mv_text {
  position: absolute;
  top: 10%;
  left: 15%;
}

.mv_text h2 {
  margin-top: 20px;
  line-height: 1.1;
}

.mv_text .flex_area {
  margin-top: 20px;
  align-items: center;
}

.mv_text .blue_b {
  text-align: center;
  width: 17rem;
  padding: 10px 0;
  background-color: #37b2b2;
  color: #fff;
  margin-right: 5px;
}

.mv_text .text_u {
  margin-top: 15px;
}

.section1_wrap {
  width: 100%;
  margin-top: 30px;
  background: linear-gradient(102deg, #37b2b2 0%, #37b2b2 65%, white 50%, white 100%);
}

.section1_wrap .se1_in {
  padding: 40px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-around;
}

.section1_wrap .se1_in .se1_title {
  line-height: 1.7;
}

.section1_wrap .se1_in .se1_utext {
  word-wrap: break-word;
  margin-top: 30px;
  line-height: 1.7;
}

.section1_wrap .se1_in .se1_img {
  margin-top: 40px;
  position: relative;
}

.section1_wrap .se1_in .s_box {
  display: block;
  position: absolute;
  content: '';
  width: calc(100% + 20px);
  height: calc(100% + -13px);
  border: 2px solid #37b2b2;
  top: 20px;
  left: 0px;
  z-index: 1;
}

.section1_wrap .se1_in img {
  position: relative;
  z-index: 2;
}

.section2 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
}

.section2 h2 {
  width: 100%;
  text-align: center;
}

.section2 .se2_in {
  margin-top: 90px;
  width: 100%;
  padding: 0 60px;
}

.se2_in {
  justify-content: space-between;
}

.se2_in .se2_l {
  width: 45%;
}

.se2_in .se2_l img {
  width: 100%;
}

.se2_in .se2_l a {
  width: 60%;
  display: block;
  margin: 0 auto;
  margin-top: 22px;
  padding: 10px 65px;
  background-color: #37b2b2;
  border-radius: 45px;
  text-align: center;
  transition: 0.2s;
}

.se2_in .se2_l a:hover {
  opacity: 0.7;
}

.se2_in .se2_l p.fs_16 {
  line-height: 1.7;
  margin-top: 20px;
}

.se2_in .se2_l p.fs_20 {
  margin-top: 40px;
  text-align: center;
}

.se2_in .se2_l p.lit_tx {
  margin-top: 30px;
  line-height: 1.7;
}

.se2_in .se2_r {
  width: 45%;
}

.se2_in .se2_r img {
  width: 100%;
}

.se2_in .se2_r a {
  width: 60%;
  display: block;
  margin: 0 auto;
  margin-top: 22px;
  padding: 10px 65px;
  background-color: #37b2b2;
  border-radius: 45px;
  text-align: center;
  transition: 0.2s;
}

.se2_in .se2_r a:hover {
  opacity: 0.7;
}

.se2_in .se2_r p.fs_16 {
  line-height: 1.7;
  margin-top: 20px;
}

.section3 {
  width: 100%;
  background-color: #e8e8e8;
  margin-top: 110px;
}

.section3 .se3_in_wrap {
  max-width: 1200px;
  margin: 0 auto;
  background-image: url("../img/b1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.section3 .se3_in {
  width: 100%;
  padding: 70px 110px;
}

.section3 .se3_in h2 {
  text-align: center;
}

.section3 .se3_in p.fs_18 {
  margin-top: 30px;
  line-height: 1.7;
}

.section4 {
  margin-top: 90px;
  width: 100%;
}

.section4 .se4_in_wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section4 .se4_in_wrap h2 {
  width: 100%;
  text-align: center;
}

.se4_in {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.se4_in .colum_wrap {
  margin-top: 40px;
  width: 30%;
  line-height: 0;
}

.se4_in .colum_wrap img {
  width: 100%;
  transition: 0.2s;
}

.se4_in .colum_wrap img:hover {
  opacity: 0.7;
}

.se4_in .colum_wrap h3 {
  width: 100%;
  text-align: center;
  margin-top: 45px;
}

.se4_in .colum_wrap hr {
  margin-top: 30px;
  border: solid 1px #37b2b2;
}

.se4_in .colum_wrap .se4_text {
  margin-top: 15px;
}

.se4_in .colum_wrap .se4_text p {
  line-height: 1.5;
}

.section5 {
  width: 100%;
  background-image: url("../img/b2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 90px;
}

.section5 .se5_in_wrap {
  width: 100%;
  max-width: 1200px;
  padding: 90px 0;
  margin: 0 auto;
}

.section5 .se5_in_wrap h2 {
  width: 100%;
  text-align: center;
}

.se5_in {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.se5_in .colum_wrap {
  margin-top: 40px;
  width: 30%;
  line-height: 0;
}

.se5_in .colum_wrap img {
  width: 100%;
  transition: 0.2s;
}

.se5_in .colum_wrap img:hover {
  opacity: 0.7;
}

.se5_in .colum_wrap h3 {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  padding: 24px 0;
  background-color: #37b2b2;
}

.se5_in .colum_wrap .se5_text {
  margin-top: 15px;
}

.se5_in .colum_wrap .se5_text p {
  line-height: 1.5;
}

.foot_wrap {
  width: 100%;
  background-color: #37b2b2;
  padding-top: 20px;
}

.foot_wrap .foot_u {
  width: 100%;
  background-color: #464646;
}

.foot_wrap .foot_u .foot_u_in {
  padding: 30px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.foot_wrap .foot_u .foot_u_in p {
  margin-top: 15px;
  text-align: center;
}

.foot_wrap .foot_u .foot_u_in ul {
  align-items: center;
  justify-content: center;
}

.foot_wrap .foot_u .foot_u_in ul li {
  padding: 4px 10px;
  transition: 0.2s;
}

.foot_wrap .foot_u .foot_u_in ul li:hover {
  opacity: 0.7;
}

.foot_wrap .foot_u .foot_u_in ul li a {
  color: #fff;
}

.foot_wrap .foot_u .foot_u_in ul li + li {
  border-left: 3px solid #fff;
}
